<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';
import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      /** @desc 表单配置参数 */
      formConfig: {},
      /** @desc 请假参数配置 */
      formData: {
        year: '',
      },
      /** @desc 弹窗配置参数 */
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      options: [],
      /** @desc 搜索框绑定数据 */
      searchFormData: {},
    };
  },
  async created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    await this.getConfigList('sfa_activity_activityProgram_list');
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    cellClick({ row, column }) {
      if (column.property === 'activityCode') {
        this.formConfig.type = 'view';
        this.formConfig.id = row.id;
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      if (val.code === 'activity_apply') {
        this.formConfig.type = val.code;
        this.openFull();
      } else if (val.code === 'edit') {
        // 审批中（bpmState=2）和审批通过（bpmState=3）不能编辑
        if (row.bpmState === '2') {
          this.$message({
            type: 'warning',
            message: '审批中的活动不能编辑！',
          });
        } else if (row.bpmState === '3') {
          this.$message({
            type: 'warning',
            message: '审批通过的活动不能编辑！',
          });
        } else {
          this.formConfig.type = val.code;
          this.formConfig.id = row.id;
          this.modalConfig.title = '编辑';
          this.openFull();
        }
      }
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>
